import WebApi from "../Api/WebApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//Metodos asincronos generales
export const obtenerSolicitudesGeneral = createAsyncThunk(
  "solicitudes/obtenerSolicitudesGeneral",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/solicitudesAdm?rowCount=${item.rowCount ?? 15}&page=${item.currentPage ?? 1}&estadoId=${item.estadoId}&carreraId=${item.carreraId}&nombre=${item.nombre}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerPeriodoIngreso = createAsyncThunk(
  "solicitudes/obtenerPeriodoIngreso",
  async (SolicitudId, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/ObtenerPeriodosIngreso/${SolicitudId}`);
      // console.log(res.data)
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerTiposIngreso = createAsyncThunk(
  "solicitudes/obtenerTiposIngreso",
  async (_, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/ObtenerTiposIngreso`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const nullifyPeriodoIngreso = createAsyncThunk(
  "solicitudes/obtenerPeriodoIngreso",
  () => null,
);
export const obtenerCarrerasActivas = createAsyncThunk(
  "solicitudes/obtenerCarrerasActivas",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/SolicitudAdm/Carreras");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudDetalle = createAsyncThunk(
  "admin/obtenerSolicitudDetalle",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "admin/SolicitudesAdm/ObtenerSolicitudDetalle/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ObtenerArchivo = createAsyncThunk(
  "admin/ObtenerArchivo",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/Archivos/" + data.id, {});
      let contentType = res.data.result.contentType;
      let base64Data = res.data.result.data;
      let fileName = res.data.result.archivoNombre;

      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      return { operationStatus: "Success", message: "Archivo descargado" };
    } catch (err) {
      return {
        operationStatus: "Fail",
        message: "Ocurrio un error al descargar el archivo",
      };
    }
  }
);

export const archivoData = createAsyncThunk(
  "admin/ObtenerArchivoData",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/Archivos/" + id, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));

      return { tipo: res.data.type.split("/")[1], url };
    } catch (err) {
      return {
        operationStatus: "Fail",
        message: "Ocurrio un error al descargar el archivo",
      };
    }
  }
);
export const DescargarArchivos = createAsyncThunk(
  "admin/ObtenerArchivoData",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/descargarArchivos/" + id, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = url;
      anchorElement.download = id + ".zip";

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(url);

      return { operationStatus: "Success", message: "Archivo descargado" };
    } catch (err) {
      return {
        operationStatus: "Fail",
        message: "No se encontraron archivos del Id indicado",
      };
    }
  }
);

export const obtenerCurriculumsByCarreraId = createAsyncThunk(
  "solicitudes/obtenerCurriculumsByCarreraId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/curriculums/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cambiarEstadoSolicitudAdm = createAsyncThunk(
  "solicitudes/cambiarEstadoSolicitudAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("admin/solicitudesAdm/CambiarEstado", {
        id: item.solicitudId,
        estadoId: item.estadoId,
        razonEstado: item.razonEstado,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const AgregarProrrogaAdm = createAsyncThunk(
  "solicitudes/AgregarProrrogaAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "admin/solicitudesAdm/solicitudesDocumentosProrrogas",
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const asignarSolicitud = createAsyncThunk(
  "solicitudes/AsignarSolicitud",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/solicitudesAdm/AsignarSolicitud?usuarioId=${item.usuarioId}&solicitudId=${item.solicitudId}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const validarSicobatecManual = createAsyncThunk(
  "solicitudes/validarSicobatecManual",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/solicitudesAdm/ValidadoSicobatecManual",
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actualizarSolicitud = createAsyncThunk(
  "solicitudes/actualizarSolicitud",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/SolicitudesAdm/SolicitudDetalle/" + item.id,
        item.data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const actualizarArchivos = createAsyncThunk(
  "solicitudes/actualizarArchivos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/SolicitudesAdm/SolicitudDetalle/" + item.id,
        item.data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Sicobatec
export const obtenerPersonasSicobatec = createAsyncThunk(
  "solicitudes/obtenerPersonasSicobatec",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/sicobatec?personaId=" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const actualizarCombrobacionSolicitud = createAsyncThunk(
  "solicitudes/actualizarSolicitudById",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/sicobatec/" + item.personaId,
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const EditarSolicitudIngreso = createAsyncThunk(
  'admin/SolicitudesPeriodoIngreso',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "admin/SolicitudesPeriodoIngreso", data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const agregarArchivoSicobatec = createAsyncThunk(
  "admin/agregarArchivoSicobatec",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "admin/SolicitudesAdm/Sicobatec/Archivo",
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const ActualizarDocumentoAdministrativo = createAsyncThunk(
  "admin/ActualizarDocumentosAdministrativos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        `admin/SolicitudesAdm/ActualizarDocumentoAdministrativo?ArchivoId=${data.archivoId}&archivoNombre=${data.archivoNombre}`,
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const eliminarArchivoSicobatec = createAsyncThunk(
  "admin/eliminarArchivoSicobatec",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        `admin/SolicitudesAdm/Sicobatec/Archivo?solicitudId=${data.solicitudId}&archivoId=${data.archivoId}`,
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);

//Checklist
export const obtenerPasosSolicitud = createAsyncThunk(
  "solicitudes/obtenerPasosSolicitud",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/checklist/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerBecas = createAsyncThunk(
  "becas/ObtenerBecas",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/becas/ucenfotec?anio=${data.anio}&termId=${data.periodo}&session=${data.session}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerNivelesBecas = createAsyncThunk(
  "becas/obtenerNivelesBecas",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/Levels/ucenfotec?offeringId=${id}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerPatrocinios = createAsyncThunk(
  "becas/obtenerPatrocinios",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/Patrocinios/ucenfotec?anio=${data.anio}&termId=${data.periodo}&organizationCode=${data.patrocinador}&session=${data.session}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerPatrocinadores = createAsyncThunk(
  "becas/obtenerPatrocinadores",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/Patrocinadores/ucenfotec`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ArchivosBecas = createAsyncThunk(
  "becas/ArchivosBecas",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/SolicitudesAdm/Documentos/Becas`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ArchivosPatrocinios = createAsyncThunk(
  "becas/ArchivosPatrocinios",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/SolicitudesAdm/Documentos/Patrocinios`,
        data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const eliminarArchivoB = createAsyncThunk(
  "becas/eliminarArchivo",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete(`admin/Becas/Archivos/` + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const obtenerTerminos = createAsyncThunk(
  "solicitudes/obtenerTerminos",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "admin/SolicitudesAdm/ObtenerTerminosCondiciones"
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const actualizarTerminos = createAsyncThunk(
  "solicitudes/obtenerTerminos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/SolicitudesAdm/ActualizarTerminosCondiciones",
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Metodos asincronos del detalle

export const obtenerProvinciasCostaRica = createAsyncThunk(
  "solicitudes/obtenerProvinciasCostaRica",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/Provincias");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerCantonesByProvinciaId = createAsyncThunk(
  "solicitudes/obtenerCantonesByProvinciaId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/Cantones/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerDistritosByProvinciaId = createAsyncThunk(
  "solicitudes/obtenerDistritosByProvinciaId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/Distritos/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerCentrosEducativos = createAsyncThunk(
  "solicitudes/obtenerCentrosEducativos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/solicitudesAdm/ObtenerPersonasCentrosEducativos/?paisId=${data.paisId}&centroEducativo=${data.centroEducativo}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerSolicitudActividades = createAsyncThunk(
  "solicitudes/obtenerSolicitudActividades",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Admin/SolicitudesActividades/${id}`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const solicitudesExportar = createAsyncThunk(
  "solicitudes/solicitudesExportar",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(`Admin/SolicitudesExportar`, data, {
        responseType: "blob",
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerArchivosLista = createAsyncThunk(
  "solicitudes/archivosSolicitudes",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Admin/Solicitudes/Archivos?rowCount=${item.rowCount}&page=${item.page}`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//becas y prorrogas checklist
export const removerProrrogaAdm = createAsyncThunk(
  "solicitudes/removerProrrogaAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(`admin/EliminarProrrogas`, item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actualizarProrrogaAdm = createAsyncThunk(
  "solicitudes/actualizarProrrogaAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("admin/CambioProrroga", item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const removerDocumentoAdm = createAsyncThunk(
  "solicitudes/removerDocumentoAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(`Admin/SolicitudesDocumentos`, item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const agregarOtrosDocumentosAdm = createAsyncThunk(
  "solicitudes/agregarOtrosDocumentosAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/DocumentosAdmisionExtra/${item.solicitudId}`,
        item.fData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const agregarBecaAdm = createAsyncThunk(
  "solicitudes/agregarBecaPatrocinioAdm",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/SolicitudesAdm/SolicitudDetalleBeca/${item.solicitudId}`,
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const agregarPatrocinioAdm = createAsyncThunk(
  "solicitudes/agregarPatrocinioAdm",
  async (item, { rejectWithValue }) => {
    try {
      const id = item.solicitudId;
      delete item.solicitudId;
      const res = await WebApi.api().post(
        `Admin/SolicitudesAdm/SolicitudDetallePatrocinio/${id}`,
        item
      );

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const obtenerSolicitudesBecasPatrocinios = createAsyncThunk(
  "admin/obtenerSolicitudesBecasPatrocinios",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `admin/SolicitudesAdm/SolicitudDetalleInfo?rowCount=${item.rowCount}&page=${item.page}&filtro=${item.filtro}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerEstudiantesMatriculados = createAsyncThunk(
  "admin/obtenerEstudiantesMatriculados",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        // admin/EstudiantesMatriculados?anio=2023&periodoId=306&estadoId=0 POST -> admin/EstudiantesMatriculadosExcel?anio=2023&periodoId=306&estadoId=0
        `admin/EstudiantesMatriculados?anio=${item.anio}&periodoId=${item.periodoId}&estadoId=${item.estadoId}&fechaDesde=${item.fechaDesde}&fechaHasta=${item.fechaHasta}&atendidoPor=${item.atendidoPor}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportarEstudiantesMatriculados = createAsyncThunk(
  "admin/exportarEstudiantesMatriculados",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/EstudiantesMatriculadosExcel?anio=${item.anio}&periodoId=${item.periodoId}&estadoId=${item.estadoId}&fechaDesde=${item.fechaDesde}&fechaHasta=${item.fechaHasta}&atendidoPor=${item.atendidoPor}`,
        {
          // responseType: 'blob',
        }
      );

      if (res.data.result) {
        const aElement = document.createElement("a");
        const fileName = `Estudiantes matriculados`;
        aElement.setAttribute("download", fileName);
        const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.result}`;
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      }

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const obtenerEstudiantesConAsistencia = createAsyncThunk(
  "admin/obtenerEstudiantesConAsistencia",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/EstudiantesConAsistencia?anio=${item.anio}&periodoId=${item.periodoId}`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportarEstudiantesConAsistencia = createAsyncThunk(
  "admin/exportarEstudiantesConAsistencia",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `admin/EstudiantesConAsistenciaExcel?anio=${item.anio}&periodoId=${item.periodoId}`,
        {
          // responseType: 'blob',
        }
      );

      if (res.data.result) {
        const aElement = document.createElement("a");
        const fileName = `Estudiantes con asistencia`;
        aElement.setAttribute("download", fileName);
        const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.result}`;
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      }

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const solicitudesAdmSlice = createSlice({
  name: "solicitudesAdm",
  initialState: {
    solicitudes: [],
    solicitudesBecasPatrocinios: { result: [] },
    solicitudDetalle: {},
    pasosSolicitud: {},
    periodoIngreso: null,
    matriculado: false,
    solicitudesAdmin: { result: [] },
    solicitudesActividades: [],
    provincias: [],
    cantones: [],
    distritos: [],
    centrosEducativos: [],
    personasSicobatec: [],
    archivosListado: { result: [] },
    curriculumns: [],
    carrerasActivas: [],
    terminos: {},
    tiposPeriodosIngreso: [],
    result: { success: false, message: "", show: false },
    status: "",
    error: null,
    loading: false,
    loadingGeneral: false,
    loadingSicobatec: false,
    loadingEstado: false,
    loadingDetalle: false,
    loadingArchivos: false,
    loadingSolicitudesBecasPatrocinios: false
  },
  reducers: {},
  extraReducers: {
    [obtenerCarrerasActivas.fulfilled]: (state, action) => {
      state.carrerasActivas = action.payload;
    },
    [obtenerTiposIngreso.fulfilled]: (state, action) => {
      state.tiposPeriodosIngreso = action.payload;
    },
    [obtenerPeriodoIngreso.fulfilled]: (state, action) => {
      // console.log(action.payload,'aqui')
      state.periodoIngreso = action.payload.result[0];
      state.matriculado=action.payload.matriculado
    },
    [obtenerSolicitudesGeneral.fulfilled]: (state, action) => {
      state.solicitudesAdmin = action.payload;
      state.loadingGeneral = false;
    },
    [obtenerSolicitudesGeneral.pending]: (state, action) => {
      state.loadingGeneral = true;
    },
    [obtenerSolicitudDetalle.fulfilled]: (state, action) => {
      state.solicitudDetalle = action.payload;
      state.loadingDetalle = false;
    },
    [obtenerSolicitudDetalle.pending]: (state, action) => {
      state.solicitudDetalle = [];
      state.loadingDetalle = true;
    },
    [obtenerSolicitudDetalle.rejected]: (state, action) => {
      state.solicitudDetalle = [];
      state.loadingDetalle = false;
    },
    [obtenerCurriculumsByCarreraId.fulfilled]: (state, action) => {
      state.curriculumns = action.payload;
    },
    [obtenerProvinciasCostaRica.fulfilled]: (state, action) => {
      state.provincias = action.payload;
    },
    [obtenerCantonesByProvinciaId.fulfilled]: (state, action) => {
      state.cantones = action.payload;
    },
    [obtenerDistritosByProvinciaId.fulfilled]: (state, action) => {
      state.distritos = action.payload;
    },
    [cambiarEstadoSolicitudAdm.fulfilled]: (state, action) => {
      state.result = action.payload;
      state.loadingEstado = false;
    },
    [cambiarEstadoSolicitudAdm.pending]: (state, action) => {
      state.loadingEstado = true;
    },
    [cambiarEstadoSolicitudAdm.rejected]: (state, action) => {
      state.loadingEstado = false;
    },
    [actualizarSolicitud.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerArchivosLista.fulfilled]: (state, action) => {
      state.archivosListado = action.payload;
      state.loadingArchivos = false;
    },
    [obtenerArchivosLista.pending]: (state, action) => {
      state.loadingArchivos = true;
    },
    [actualizarArchivos.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerCentrosEducativos.fulfilled]: (state, action) => {
      state.centrosEducativos = action.payload;
    },
    [obtenerPersonasSicobatec.fulfilled]: (state, action) => {
      state.personasSicobatec = action.payload;
      state.loadingSicobatec = false;
    },
    [obtenerPersonasSicobatec.pending]: (state, action) => {
      state.loadingSicobatec = true;
    },
    [obtenerPersonasSicobatec.rejected]: (state, action) => {
      state.loadingSicobatec = false;
    },
    [obtenerPasosSolicitud.fulfilled]: (state, action) => {
      state.pasosSolicitud = action.payload;
    },
    [obtenerTerminos.fulfilled]: (state, action) => {
      state.terminos = action.payload;
    },
    [obtenerSolicitudActividades.fulfilled]: (state, action) => {
      state.solicitudesActividades = action.payload;
      state.loading = false;
    },
    [obtenerSolicitudActividades.pending]: (state, action) => {
      state.solicitudesActividades = action.payload;
      state.loading = true;
    },
    [obtenerSolicitudesBecasPatrocinios.fulfilled]: (state, action) => {
      state.solicitudesBecasPatrocinios = action.payload;
      state.loadingSolicitudesBecasPatrocinios = false;
    },
    [obtenerSolicitudesBecasPatrocinios.pending]: (state, action) => {
      state.loadingSolicitudesBecasPatrocinios = true;
    },
  },
});

export default solicitudesAdmSlice.reducer;
