import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudesGeneral,
  obtenerPersonasSicobatec,
  obtenerPasosSolicitud,
  solicitudesExportar,
  actualizarCombrobacionSolicitud,
  obtenerCarrerasActivas,
  EditarSolicitudIngreso,
  obtenerPeriodoIngreso,
  nullifyPeriodoIngreso,
  obtenerTiposIngreso,
  obtenerBecas,
  obtenerNivelesBecas,
} from "../../redux/solicitudesAdmSlice";
import RolesConfig from "../../config/Roles";
import { convertToBoolean, extractRoles } from "../../Utils/utils";

import { obtenerEstadosSolicitud } from "../../redux/genericosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import OperationStatus from "../../config/OperationStatus";
import FileViewer from "../../Components/FileViewer";
import { OrderTable } from "../../Components/OrderTable";
import useValidarSicobatec from "../Hooks/useValidarSicobatec";
import Required from "../../Components/Required";
import { ObtenerPeriodosTipoYAño } from "../../redux/periodosSlice";
import Pagination from "./Paginacion";
import LoadingSpiner from "../../Components/LoadingSpiner";
import MessageInfo from "./MessageInfo";

const columns = [
  { label: "Solicitud no.", accessor: "numero", sortable: true },
  { label: "Nombre", accessor: "nombre", sortable: true },
  { label: "Fecha de envio", accessor: "fechaFormato", sortable: true },
  { label: "Carrera", accessor: "carrera", sortable: true },
  { label: "Atendido por", accessor: "atendidoPor", sortable: true },
  { label: "Estatus", accessor: "estatus", sortable: true },
  { label: "Acción", accessor: "action", sortable: false },
];

export default function ListaSolicitudes(props) {
  const Rol = RolesConfig;
  const roles = extractRoles();

  const [rowCount, setRowCount] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyForce, setKeyForce] = useState(0);

  const [filtro, setFiltro] = useState({
    busqueda: "",
    estadoId: 0,
    carreraId: 0,
  });
  const [modalSicobatecState, changeModalSicobatecState] = useState(false);
  const [modalCheckState, changeModalCheckState] = useState(false);
  const [itemSelected, selectItem] = useState();
  const [personaSelected, selectPersona] = useState();
  const [showFileModal, setShowFileModal] = useState(false);
  const [modalEditSolicitud, setModalEditSolicitud] = useState(null);
  const [becas, setBecas] = useState([]);
  const [nivelesBecas, setNivelesBecas] = useState([]);

  const {
    validadoManualmente,
    setValidadoManualmente,
    noAplicaSicobatec,
    setNoAplicaSicobatec,
    verificarDespuesSicobatec,
    setVerificarDespuesSicobatec,
    validarSicobatecManualmente,
  } = useValidarSicobatec(itemSelected?.id);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const busqueda = useRef();

  const { periodosAnioTipo } = useSelector((state) => state.periodos);
  const { estadosSolicitud } = useSelector((state) => state.genericos);
  const {
    solicitudesAdmin,
    personasSicobatec,
    loadingGeneral,
    pasosSolicitud,
    carrerasActivas,
    periodoIngreso,
    matriculado,
    tiposPeriodosIngreso,
  } = useSelector((state) => state.solicitudesAdm);

  const {
    reset: resetEdit,
    control: controlEdit,
    setValue: setValueEdit,
    handleSubmit: handleSubmitEdit,
    watch,
  } = useForm();

  const anioEdit = useWatch({
    control: controlEdit,
    name: "anio",
    exact: true,
  });
  const periodoId = useWatch({
    control: controlEdit,
    name: "periodoId",
    exact: true,
  });

  const becaId = watch("becaId");

  const periodoSelected = useMemo(() => {
    return periodosAnioTipo?.find(
      (pat) => pat.periodoId === parseInt(periodoId, 10)
    );
  }, [periodoId, periodosAnioTipo]);

  useEffect(() => {
    if (becaId != undefined && becaId != "") {
      dispatch(obtenerNivelesBecas(becaId)).then((res) => {
        if (Array.isArray(res.payload)) setNivelesBecas(res.payload);
        else setNivelesBecas([]);
      });
    }
  }, [becaId]);

  useEffect(() => {
    if (anioEdit && periodoId) {
      dispatch(
        obtenerBecas({
          periodo: periodoId,
          anio: anioEdit,
          session: modalEditSolicitud.sesionId,
        })
      ).then((res) => {
        if (Array.isArray(res.payload)) setBecas(res.payload);
        else setBecas([]);
      });
    }
  }, [anioEdit, periodoId]);

  useEffect(() => {
    if (
      periodoIngreso &&
      periodoIngreso?.solicitudId === modalEditSolicitud?.id
    ) {
      setValueEdit("anio", periodoIngreso.anio);
      setValueEdit("periodoId", periodoIngreso.periodoId);
      setValueEdit("TipoIngresoId", periodoIngreso.tipoIngresoId);
      setValueEdit("becaId", periodoIngreso.becaId);
      setValueEdit("nivelId", periodoIngreso.nivelId);
      setKeyForce((prev) => prev++);
    }
  }, [modalEditSolicitud?.id, periodoIngreso]);

  useEffect(() => {
    let object = {
      anio: anioEdit ?? new Date().getFullYear(),
      carreraId: modalEditSolicitud?.carreraId ?? 0,
    };
    dispatch(ObtenerPeriodosTipoYAño(object));
    resetEdit({ anio: anioEdit ?? new Date().getFullYear(), periodoId: null });
  }, [anioEdit, modalEditSolicitud?.carreraId, resetEdit]);

  useEffect(() => {
    dispatch(obtenerTiposIngreso());
  }, [dispatch]);

  useEffect(() => {
    if (
      (personaSelected?.id != null || personaSelected?.id != undefined) &&
      personaSelected.personaId
    ) {
      let personaId = personaSelected.personaId;
      let personaSicobatecId = personaSelected.id;

      dispatch(
        actualizarCombrobacionSolicitud({ personaId, personaSicobatecId })
      ).then((res) => {
        if (res.payload.operationStatus === OperationStatus.SUCCESS) {
          changeModalSicobatecState(false);
          cargarSolicitudes();
          toast.success(res.payload.message);
        } else {
          toast.error(res.payload.message);
        }
      });
    }
  }, [personaSelected]);

  const { width } = WindowDimension();

  const { reset } = useForm();

  const closeModalSicobatec = () => {
    changeModalSicobatecState(false);
  };

  const handleResetPagination = () => {
    setCurrentPage(1);
    setRowCount(15);
  };

  const closeModal = () => {
    changeModalCheckState(false);
  };

  const cargarSolicitudes = () => {
    dispatch(
      obtenerSolicitudesGeneral({
        estadoId: filtro.estadoId,
        carreraId: filtro.carreraId,
        nombre: filtro.busqueda,
      })
    );
  };

  const cargarSolicitudesPorEstado = (e) => {
    handleResetPagination();
    setFiltro({
      estadoId: e.target.value,
      carreraId: filtro.carreraId,
      busqueda: filtro.busqueda,
    });
    dispatch(
      obtenerSolicitudesGeneral({
        estadoId: e.target.value,
        carreraId: filtro.carreraId,
        nombre: filtro.busqueda,
      })
    );
    localStorage.setItem(
      "filtro",
      JSON.stringify({
        estadoId: e.target.value,
        carreraId: filtro.carreraId,
        nombre: filtro.busqueda,
      })
    );
  };

  const cargarSolicitudesPorCarrera = (e) => {
    handleResetPagination();
    setFiltro({
      carreraId: e.target.value,
      estadoId: filtro.estadoId,
      busqueda: filtro.busqueda,
    });
    dispatch(
      obtenerSolicitudesGeneral({
        estadoId: filtro.estadoId,
        carreraId: e.target.value,
        nombre: filtro.busqueda,
      })
    );
    localStorage.setItem(
      "filtro",
      JSON.stringify({
        estadoId: filtro.estadoId,
        carreraId: e.target.value,
        nombre: filtro.busqueda,
      })
    );
  };

  const cargarSolicitudesPorCaracter = () => {
    handleResetPagination();
    setFiltro({
      carreraId: filtro.carreraId,
      estadoId: filtro.estadoId,
      busqueda: busqueda.current.value,
    });

    dispatch(
      obtenerSolicitudesGeneral({
        estadoId: filtro.estadoId,
        carreraId: filtro.carreraId,
        nombre: busqueda.current.value,
      })
    );
    localStorage.setItem(
      "filtro",
      JSON.stringify({
        estadoId: filtro.estadoId,
        carreraId: filtro.carreraId,
        nombre: busqueda.current.value,
      })
    );
  };

  const exportarSolicitud = async () => {
    dispatch(solicitudesExportar);
    let { payload } = await dispatch(
      solicitudesExportar({
        estadoId: filtro.estadoId,
        carreraId: filtro.carreraId,
        busqueda: filtro.busqueda,
      })
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([payload]));

    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", "Listado solicitudes.xlsx"); //any other extension

    document.body.appendChild(link);

    link.click();

    link.remove();
  };

  const cambiarEstadoSicobatec = async (e) => {
    cargarSolicitudes();
    dispatch(obtenerPasosSolicitud(itemSelected.id));
    changeModalCheckState(false);
    changeModalSicobatecState(false);
  };

  const navigateToHistory = (item) => {
    navigate(`/Admin/SolicitudActividades/${item.id}`, {
      state: { numero: item.numero },
    });
  };

  const handleEditSolicitudCancel = () => {
    setModalEditSolicitud(null);
    dispatch(nullifyPeriodoIngreso());
    resetEdit({ periodoId: null, anio: new Date().getFullYear() });
  };

  const viewSolicitud = (item) => {
    selectItem(item);

    setValidadoManualmente(item.validadoManualmenteEnSicobatec);
    setVerificarDespuesSicobatec(item.verificarDespuesSicobatec);
    setNoAplicaSicobatec(item.noAplicaSicobatec);
    if (
      item.estadoId === 9 ||
      item.estadoId === 11 ||
      item.estadoId === 18 ||
      item.estadoId === 19
    ) {
      navigate(`/Admin/detalleSolicitud/${item.id}`, {
        state: { numero: item.numero },
      });
    } else {
      dispatch(obtenerPasosSolicitud(item.id));
      changeModalCheckState(true);
    }
  };

  const viewSicobatec = (item) => {
    setValidadoManualmente(item.validadoManualmenteEnSicobatec);
    setVerificarDespuesSicobatec(item.verificarDespuesSicobatec);
    setNoAplicaSicobatec(item.noAplicaSicobatec);

    dispatch(obtenerPersonasSicobatec(item.personaId));
    changeModalSicobatecState(true);
  };

  useEffect(() => {
    reset(personasSicobatec);

    personasSicobatec.forEach((item) => {
      if (item.coincide) {
        // selectPersona(item);
      }
    });
  }, [personasSicobatec]);

  useEffect(() => {
    reset(pasosSolicitud);
  }, [pasosSolicitud]);

  useEffect(() => {
    let item = JSON.parse(localStorage.getItem("filtro"));
    let carreraId = 0;
    let estadoId = 0;
    let busqueda = "";

    if (item != null) {
      setFiltro({
        carreraId: item.carreraId,
        estadoId: item.estadoId,
        busqueda: item.nombre,
      });
      carreraId = item.carreraId;
      estadoId = item.estadoId;
      busqueda = item.nombre;
    }
    dispatch(
      obtenerSolicitudesGeneral({
        estadoId: estadoId,
        carreraId: carreraId,
        nombre: busqueda,
        rowCount,
        currentPage,
      })
    );

    // cargarSolicitudes();
    dispatch(obtenerEstadosSolicitud());
    dispatch(obtenerCarrerasActivas());
  }, [currentPage, rowCount]);

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <div className="select-filter-section-ml-auto">
          <label hidden>Estado:</label>
          <select value={filtro.estadoId} onChange={cargarSolicitudesPorEstado}>
            <option hidden>Estado</option>
            <option value={0}>Todos los estados</option>
            {estadosSolicitud.map((a, i) => (
              <option key={i} value={a.estadoId}>
                {a.estadoNombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-filter-section-ml-auto">
          <label hidden>Carrera:</label>
          <select
            value={filtro.carreraId}
            onChange={cargarSolicitudesPorCarrera}
          >
            <option hidden>Carrera</option>
            <option value={0}>Todas las carreras</option>
            {carrerasActivas.map((a, i) => {
              return (
                <option key={i} value={a.id}>
                  {a.carreraNombre}
                </option>
              );
            })}
          </select>
        </div>
        <div className="header-admin-bar-items ml-auto">
          <label hidden>Búsqueda</label>
          <input
            defaultValue={filtro.busqueda}
            type="input"
            placeholder="Búsqueda"
            ref={busqueda}
            className="form-control"
            onInput={cargarSolicitudesPorCaracter}
          />
          <button
            className="search-button"
            title="Exportar"
            onClick={exportarSolicitud}
          >
            <i className="material-icons"></i>
          </button>
        </div>
      </div>
      {}
      <div className="table-container relative-class">
        {loadingGeneral ? (
          <div className="align-spinner">
            <LoadingSpiner
              styles={{
                width: "45px",
                height: "45px",
                border: "6px solid #bbbbbb",
                borderTop: "6px solid #035cc9",
              }}
            />
          </div>
        ) : (
          <>
            {solicitudesAdmin?.result?.length > 0 ? (
              <>
                <OrderTable
                  {...{ columns }}
                  data={solicitudesAdmin?.result ?? []}
                  clase="table-adm"
                  actions={[
                    { icon: "", clase: "text-primary", event: viewSolicitud },
                    {
                      icon: "",
                      clase: "text-primary",
                      event: navigateToHistory,
                    },
                    roles?.includes(Rol.DIRECTOR)
                      ? {
                          icon: "",
                          clase: "text-primary",
                          condicional: (item) => item.estadoId !== 10,
                          event: (item) => {
                            setValueEdit("becaId",null);
                            setValueEdit("nivelId", null);
                            dispatch(obtenerPeriodoIngreso(item.id));
                            setModalEditSolicitud(item);
                          },
                        }
                      : {},
                  ]}
                />
              </>
            ) : (
              <div className="no-results-container">
                <img src={props.img} alt="Imagen sin resultados" />
                <h1>{props.noResultMessage}</h1>
              </div>
            )}
          </>
        )}
      </div>
      <Pagination
        rowCount={rowCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowCountChange={setRowCount}
        totalPages={solicitudesAdmin.pageCount}
      />
      <FileViewer
        estado={showFileModal}
        cambiarEstado={() => {
          setShowFileModal(false);
        }}
        docs={[]}
      />
      <Modal
        estado={!!modalEditSolicitud}
        titulo={"Editar periodo ingreso"}
        cambiarEstado={handleEditSolicitudCancel}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button className="button-text" onClick={handleEditSolicitudCancel}>
              Cancelar
            </button>
            <button
              type="submit"
              onClick={handleSubmitEdit(async (values) => {
                const result = await dispatch(
                  EditarSolicitudIngreso({
                    Anio: parseInt(values.anio, 10),
                    SolicitudId: modalEditSolicitud.id,
                    PeriodoId: parseInt(values.periodoId, 10),
                    TipoIngresoId: parseInt(values.TipoIngresoId ?? "1", 10),
                    nivelId: values.nivelId===""?null:values.nivelId,
                    becaId: values.becaId===""?null:values.becaId,
                  })
                );

                if (result.payload?.operationStatus === "Fail") {
                  toast.error(result.payload.message);
                  return;
                }

                toast.success(result.payload.message);

                setModalEditSolicitud(null);
                resetEdit();
              })}
              className="button-text"
            >
              Guardar
            </button>
          </>
        }
      >
        {matriculado && (
          <MessageInfo
            message={
              "El estudiante ha matriculado para el periodo de la solicitud, verifique antes de modificar el periodo de ingreso"
            }
          />
        )}
        <form key={keyForce}>
          <div className="form-group">
            <label>Año</label>
            <Required />
            <Controller
              name="anio"
              control={controlEdit}
              defaultValue={new Date().getFullYear()}
              rules={{
                validate: (value) => (value ? null : "Campo Requerido"),
              }}
              render={({ field, fieldState }) => (
                <>
                  <select
                    {...field}
                    className="form-control"
                    placeholder="Seleccione un año"
                  >
                    {Array.from(
                      { length: 100 },
                      (_, index) => new Date().getFullYear() + index
                    ).map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                  {fieldState.error?.message && (
                    <p className="error">{fieldState.error?.message}</p>
                  )}
                </>
              )}
            />
          </div>
          <div className="form-group">
            <label>Periodo</label>
            <Required />
            <Controller
              name="periodoId"
              control={controlEdit}
              rules={{
                validate: (value) => (value ? null : "Campo Requerido"),
              }}
              render={({ field, fieldState }) => (
                <>
                  <select {...field} className="form-control">
                    <option value={null}>Seleccione</option>
                    {periodosAnioTipo?.map((a, i) => {
                      return (
                        <option key={i} value={a.periodoId}>
                          {a.periodoNombre} - {a.tipoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {fieldState.error?.message && (
                    <p className="error">{fieldState.error?.message}</p>
                  )}
                </>
              )}
            />
          </div>
          {periodoSelected?.periodoTipoId === 2 && (
            <div className="form-group">
              <label>Tipo de ingreso</label>
              <Required />
              <Controller
                name="TipoIngresoId"
                control={controlEdit}
                rules={{
                  validate: (value) => (value ? null : "Campo Requerido"),
                }}
                render={({ field, fieldState }) => (
                  <>
                    <select {...field} className="form-control">
                      <option value={null}>Seleccione</option>
                      {tiposPeriodosIngreso?.map((a, i) => {
                        return (
                          <option key={i} value={a.tipoIngresoId}>
                            {a.tipoNombre}
                          </option>
                        );
                      })}
                    </select>
                    {fieldState.error?.message && (
                      <p className="error">{fieldState.error?.message}</p>
                    )}
                  </>
                )}
              />
            </div>
          )}

          <div className="form-group">
            <label>Beca</label>

            <Controller
              name="becaId"
              control={controlEdit}
              render={({ field, fieldState }) => (
                <>
                  <select
                    {...field}
                    className="form-control"
                    placeholder="Seleccione"
                  >
                    <option value={0}>Sin beca</option>
                    {becas?.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {fieldState.error?.message && (
                    <p className="error">{fieldState.error?.message}</p>
                  )}
                </>
              )}
            />
          </div>
          <div className="form-group">
            <label>Nivel</label>
            {becaId && +becaId !== 0 && <Required />}

            <Controller
              name="nivelId"
              control={controlEdit}
              rules={{
                validate: (value) =>
                  !value && +becaId !== 0 ? "Campo Requerido" : null,
              }}
              render={({ field, fieldState }) => (
                <>
                  <select
                    {...field}
                    className="form-control"
                    placeholder="Seleccione"
                  >
                    <option value={undefined}>Seleccione un nivel</option>
                    {nivelesBecas?.map((item, i) => {
                      return (
                        <option key={i} value={item.levelId}>
                          {item.level}
                        </option>
                      );
                    })}
                  </select>
                  {fieldState.error?.message && (
                    <p className="error">{fieldState.error?.message}</p>
                  )}
                </>
              )}
            />
          </div>
        </form>
      </Modal>
      <Modal
        estado={modalCheckState}
        cambiarEstado={closeModal}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        titulo={`Eventos de la solicitud no. ${itemSelected?.numero}`}
        buttonGroup={<></>}
      >
        <div>
          <details className="details">
            <summary>
              <div>
                <input
                  className="invisible-check"
                  type="checkbox"
                  readOnly
                  name="formularioCompletado"
                  checked={pasosSolicitud.formularioCompletado == true}
                />
                <label className="rounded-label-checkbox">
                  Formulario completado
                </label>
              </div>
            </summary>
            <button
              className="modBtn"
              onClick={() =>
                navigate(`/Admin/detalleSolicitud/${itemSelected.id}`, {
                  state: { numero: itemSelected.numero },
                })
              }
            >
              <div className="flex-form">
                <i className="material-icons text-primary"></i>
                <span>Vista del formulario</span>
              </div>
            </button>
          </details>

          <details className="details">
            <summary>
              <div>
                <input
                  className="invisible-check"
                  readOnly
                  type="checkbox"
                  name="documentosAdjuntos"
                  checked={pasosSolicitud.documentosAdjuntos == true}
                />
                <label className="rounded-label-checkbox">
                  Documentos adjuntos
                </label>
              </div>
            </summary>
            <div className="form-group mr-2 ml-1 pl-4">
              <label className="fsize-13">
                <strong>Perfil: </strong> {pasosSolicitud.perfilNombre}
              </label>
              <br></br>
              <label className="fsize-13">
                <strong>Documentos: </strong> {pasosSolicitud.documentos}
              </label>
            </div>
          </details>

          {pasosSolicitud.requiereVerificacionSicobatec && (
            <details className="details">
              <summary>
                <div>
                  <input
                    className="invisible-check"
                    readOnly
                    type="checkbox"
                    name="sicobatecVerificado"
                    checked={pasosSolicitud.sicobatecVerificado == true}
                  />
                  <label className="rounded-label-checkbox">
                    Verificado en Sicobatec
                  </label>
                </div>
              </summary>
              <>
                {pasosSolicitud.sicobatecVerificado &&
                pasosSolicitud.validadoManualmente ? (
                  <>
                    <div className="flex-form ">
                      <div className="form-group mr-2 ml-1 pl-4">
                        <label className="fsize-13">
                          {" "}
                          Validado manualmente
                        </label>
                      </div>
                    </div>
                    <button
                      className="modBtn"
                      onClick={() => viewSicobatec(itemSelected)}
                    >
                      <div className="flex-form">
                        <i className="material-icons text-primary"></i>
                        <span>Inspeccionar coincidencia en Sicobatec</span>
                      </div>
                    </button>
                  </>
                ) : (
                  <button
                    className="modBtn"
                    onClick={() => viewSicobatec(itemSelected)}
                  >
                    <div className="flex-form">
                      <i className="material-icons text-primary"></i>
                      <span>Inspeccionar coincidencia en Sicobatec</span>
                    </div>
                  </button>
                )}
              </>
            </details>
          )}

          {itemSelected?.estadoId == 10 && (
            <>
              <details className="details">
                <summary>
                  <div>
                    <input
                      className="invisible-check"
                      readOnly
                      type="checkbox"
                      name="matriculaAsignada"
                      checked={pasosSolicitud.matriculaAsignada == true}
                    />
                    <label className="rounded-label-checkbox">
                      Matricula asignada
                    </label>
                  </div>
                </summary>
                <div className="form-group mr-2 ml-1 pl-4">
                  <label className="fsize-13">
                    {" "}
                    {pasosSolicitud.matricula}
                  </label>
                </div>
              </details>

              <details className="details">
                <summary>
                  <div>
                    <input
                      className="invisible-check"
                      readOnly
                      type="checkbox"
                      name="correoGenerado"
                      checked={pasosSolicitud.correoGenerado == true}
                    />
                    <label className="rounded-label-checkbox">
                      Correo generado
                    </label>
                  </div>
                </summary>
                <div className="form-group mr-2 ml-1 pl-4">
                  <label className="fsize-13">
                    {" "}
                    {pasosSolicitud.correoInstitucional}
                  </label>
                </div>
              </details>
            </>
          )}
        </div>
      </Modal>
      <Modal
        estado={modalSicobatecState}
        cambiarEstado={closeModalSicobatec}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        titulo={`Comprobación de solicitud no. ${itemSelected?.numero} en Sicobatec`}
        buttonGroup={<></>}
      >
        <div className="mb-2">
          <span className="modal-info-text">
            Seleccione el campo con el que coincida con:
          </span>
          <span className="subtitulo-solicitud ma-2">
            {" "}
            {itemSelected?.nombre}
          </span>{" "}
          <br />
          <span className="subtitulo-solicitud ma-2">
            {" "}
            Cédula: {itemSelected?.cedula}
          </span>
        </div>

        <form>
          {personasSicobatec.length > 0 ? (
            <>
              <span className="modal-info-text">
                Seleccione el campo con el que coincida
              </span>

              <div className="table-container">
                {personasSicobatec.length > 0 ? (
                  <table className="table-adm">
                    <thead>
                      <tr>
                        <td>Coincidencia</td>
                        <td>Nombre</td>
                        <td>Cédula</td>
                        <td>Región educativa</td>
                        <td>Institución educativa</td>
                        <td>Tipo título</td>
                        <td>Modalidad</td>
                        <td>Tomo</td>
                        <td>Folio</td>
                        <td>Asiento</td>
                        <td>Número título</td>
                        <td>Año</td>
                        <td></td>
                      </tr>
                    </thead>

                    <tbody>
                      {personasSicobatec?.map((item, i) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <input
                                type="radio"
                                name="coincide"
                                value={true}
                                defaultChecked={item.coincide == true}
                                onChange={() => selectPersona(item)}
                              />
                            </td>
                            <td>
                              {item.nombres} {item.apellidos}
                            </td>
                            <td>{item.cedula}</td>
                            <td>{item.regionEducativa}</td>
                            <td>{item.institucionEducativa}</td>
                            <td>{item.tipoTitulo}</td>
                            <td>{item.modalidad}</td>
                            <td>{item.tomo}</td>
                            <td>{item.folio}</td>
                            <td>{item.asiento}</td>
                            <td>{item.noTitulo}</td>
                            <td>{item.anio}</td>
                            <td>
                              <a
                                className="download-icon"
                                role={"button"}
                                href={`data:${item.archivo.contentType};base;base64,${item.archivo.data}`}
                                download={item.archivo.archivoNombre}
                              >
                                <i className="material-icons"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-results-container">
                    <img src={props.img} alt="Imagen sin resultados" />
                    <h1>{props.noResultMessage}</h1>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="data-empty">
              <span>No se encontraron coincidencias</span>

              <div className="inline-check">
                <input
                  value={true}
                  checked={validadoManualmente}
                  onChange={(e) => {
                    setValidadoManualmente(true);
                    setVerificarDespuesSicobatec(false);
                    setNoAplicaSicobatec(false);
                  }}
                  type="checkbox"
                />
                <label>Validado en Sicobatec manualmente</label>
              </div>
              <div className="inline-check">
                <input
                  value={true}
                  checked={verificarDespuesSicobatec}
                  onChange={(e) => {
                    setVerificarDespuesSicobatec(true);
                    setValidadoManualmente(false);
                    setNoAplicaSicobatec(false);
                  }}
                  type="checkbox"
                />
                <label>Validar en Sicobatec después</label>
              </div>
              <div className="inline-check">
                <input
                  value={true}
                  checked={noAplicaSicobatec}
                  onChange={(e) => {
                    setVerificarDespuesSicobatec(false);
                    setValidadoManualmente(false);
                    setNoAplicaSicobatec(true);
                  }}
                  type="checkbox"
                />
                <label>No aplica</label>
              </div>
              {validadoManualmente !=
                itemSelected?.validadoManualmenteEnSicobatec ||
              verificarDespuesSicobatec !=
                itemSelected?.verificarDespuesSicobatec ||
              noAplicaSicobatec != itemSelected?.noAplicaSicobatec ? (
                <button
                  className="button-text"
                  onClick={(e) =>
                    validarSicobatecManualmente(e, cambiarEstadoSicobatec)
                  }
                >
                  Guardar
                </button>
              ) : null}
            </div>
          )}
        </form>
      </Modal>
    </main>
  );
}
