import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  obtenerEstudiantesMatriculados,
  exportarEstudiantesMatriculados,
} from "../../../redux/solicitudesAdmSlice";
import { OrderTable } from "../../../Components/OrderTable";
import { ObtenerTodos } from "../../../redux/periodosSlice";
import { obtenerUsuariosGeneral } from "../../../redux/usuariosSlice";
import { toast } from "react-toastify";

export default function EstudiantesMatriculados(props) {
  const dispatch = useDispatch();
  let filtroAnio = useRef();
  const [periodoId, setPeriodoId] = useState(null);
  const [estadoId, setEstadoId] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [atendidoPor, setAtendidoPor] = useState(0);
  const [fechaDesde, setFechaDesde] = useState(new Date(null).toISOString());
  const [fechaHasta, setFechaHasta] = useState(new Date().toISOString());
  const [anio, setAnio] = useState(null);
  const [periodos, setPeriodos] = useState([]);
  const [estudiantesMatriculados, setEstudiantesMatriculados] = useState([]);
  const columns = [
    { label: "EstudianteId", accessor: "estudianteId", sortable: true },
    { label: "PersonaId", accessor: "dni", sortable: true },
    { label: "Nombres", accessor: "nombres", sortable: true },
    { label: "Carrera", accessor: "carrera", sortable: true },
    { label: "Periodo", accessor: "periodo", sortable: true },
    { label: "Año", accessor: "anio", sortable: true },
    { label: "Teléfono", accessor: "telefono", sortable: true },
    {
      label: "Correo institucional",
      accessor: "correoInstitucional",
      sortable: true,
    },
    { label: "Correo personal", accessor: "correoPersonal", sortable: true },
    { label: "Pais", accessor: "paisNombre", sortable: true },
    { label: "Provincia", accessor: "provinciaNombre", sortable: true },
    { label: "Cantón", accessor: "cantonNombre", sortable: true },
    { label: "Distrito", accessor: "distritoNombre", sortable: true },
    { label: "Atendido por", accessor: "atendidoPor", sortable: true },
    { label: "Fecha", accessor: "fecha", sortable: true },
    { label: "Estado", accessor: "estadoEstudiante", sortable: true },
    { label: "Fecha de nacimiento", accessor: "fechaNacimiento", sortable: true },
    { label: "Genero", accessor: "genero", sortable: true },
    { label: "¿Cómo financiará sus estudios?", accessor: "financiamientoNombre", sortable: true },
    { label: "Trabaja", accessor: "tieneTrabajo", sortable: true },
    { label: "Trabajo en sector informática", accessor: "trabajaEnSectorInformatica", sortable: true },
    { label: "Empresa", accessor: "empresaTrabajoNombre", sortable: true },
    { label: "Ocupación", accessor: "trabajoOcupacion", sortable: true },
    { label: "Pais de la empresa", accessor: "paisEmpresa", sortable: true },
    { label: "Pais", accessor: "paisEstudioSecundario", sortable: true },
    { label: "Estado de estudio", accessor: "estudioEstado", sortable: true },
    { label: "Centro educativo", accessor: "centroEducativo", sortable: true },
    { label: "Titulo obtenido", accessor: "tituloObtenidoEstudioSecundario", sortable: true },
    { label: "Año de conclusión", accessor: "fechaConclusionEstudioSecundario", sortable: true },
  ];

  const cambiarAnio = (e) => {
    let object = { anio: e.target.value, periodoNombre: null };
    cargarPeriodosPorAnio(object);
    setAnio(e.target.value);
  };
  const cargarPeriodosPorAnio = (a) => {
    let object = { anio: a.anio, periodoNombre: null };
    dispatch(ObtenerTodos(object)).then((d) => {
      setPeriodos(d.payload);
    });
  };
  const cargarUsuarios = () => {
    dispatch(obtenerUsuariosGeneral()).then((d) => {
      if (Array.isArray(d.payload)) setUsuarios(d.payload);
    });
  };

  const cambiarPeriodo = (e) => {
    setPeriodoId(e.target.value);
  };

  const cambiarEstado = (e) => {
    setEstadoId(e.target.value);
  };
  const cambiarAtendidoPor = (e) => {
    setAtendidoPor(e.target.value);
  };
  const cambiarFechaDesde = (e) => {
    setFechaDesde(e.target.value);
  };
  const cambiarFechaHasta = (e) => {
    setFechaHasta(e.target.value);
  };

  const descargarEstudiantesMatriculados = async () => {
    if (periodoId != null && anio != null && estadoId != null) {
      const { payload } = await dispatch(
        exportarEstudiantesMatriculados({
          periodoId: periodoId,
          anio: anio,
          estadoId: estadoId,
          fechaDesde,
          fechaHasta,
          atendidoPor,
        })
      );
    } else {
      toast.error("Faltan parametros por completar");
    }
  };

  useEffect(() => {
    if (periodoId != null && anio != null && estadoId != null) {
      dispatch(
        obtenerEstudiantesMatriculados({
          periodoId,
          anio,
          estadoId,
          fechaDesde,
          fechaHasta,
          atendidoPor,
        })
      ).then((d) => {
        setEstudiantesMatriculados(d.payload);
      });
    }
  }, [periodoId, anio, estadoId, fechaDesde, fechaHasta, atendidoPor]);

  useEffect(() => {
    if(usuarios.length===0)
    cargarUsuarios();
  }, [usuarios]);

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Estudiantes matriculados</h2>
        <div className="select-filter-section-ml-auto"></div>
        <div className="header-admin-bar-items-long ml-auto">
          <div>
            <label>Año</label>

            <select ref={filtroAnio} onChange={cambiarAnio}>
              <option value={0}>Seleccione</option>

              {new Array(40).fill(0).map((b, i) => (
                <option key={i} value={new Date().getFullYear()+1 - i}>
                  {new Date().getFullYear()+1 - i}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Periodo</label>

            <select onChange={cambiarPeriodo}>
              <option value={null}>Seleccione</option>

              {periodos?.map((item, i) => (
                <option key={i} value={item.periodoId}>
                  {item.periodoNombre}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Estado</label>
            <select onChange={cambiarEstado} defaultValue={estadoId}>
              <option value="0">Mostrar todos</option>
              <option value="1">Matriculados</option>
              <option value="2">No matriculados</option>
            </select>
          </div>

          <div>
            <label>Atendido por</label>
            <select onChange={cambiarAtendidoPor} defaultValue={atendidoPor}>
              <option value={0}>Mostrar todos</option>
              {usuarios
                ?.filter((us) => us.roles.find((rol) => rol.rolId === 3))
                .map((item, i) => (
                  <option key={i} value={item.usuarioId}>
                    {item.nombreCompleto}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label>Desde</label>
            <input
              type="date"
              defaultValue={fechaDesde}
              onChange={cambiarFechaDesde}
            />
          </div>

          <div>
            <label>Hasta</label>
            <input
              type="date"
              defaultValue={fechaHasta}
              onChange={cambiarFechaHasta}
            />
          </div>
        </div>

        <button
          className="search-button"
          title="Exportar a excel"
          onClick={descargarEstudiantesMatriculados}
        >
          <i className="material-icons-outlined"></i>
        </button>
      </div>

      <div className="table-container">
        {estudiantesMatriculados?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={estudiantesMatriculados}
              clase="table-adm"
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
    </main>
  );
}
