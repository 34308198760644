import { useState, useEffect } from 'react';
import {
  obtenerArchivosLista,
  ObtenerArchivo,
  DescargarArchivos,
} from '../../redux/solicitudesAdmSlice';

import { useDispatch, useSelector } from 'react-redux';
import OperationStatus from '../../config/OperationStatus';
import FileViewer from '../../Components/FileViewer';
import { toast } from 'react-toastify';
import { OrderTable } from '../../Components/OrderTable';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import WindowDimension from '../../Hooks/WindowDimension';
import Required from '../../Components/Required';
import LoadingSpiner from '../../Components/LoadingSpiner';
import Pagination from './Paginacion';

const columns = [
  { label: 'Código', accessor: 'archivoId', sortable: true },
  { label: 'Archivo', accessor: 'archivoNombre', sortable: true },
  { label: 'Responsable', accessor: 'nombreCompleto', sortable: true },
  { label: 'Fecha', accessor: 'fecha', sortable: true },
  { label: 'Acción', accessor: 'action', sortable: false },
];

function ListaArchivos(props) {
  const dispatch = useDispatch();
  const { width } = WindowDimension();

  const [tableData, setTableData] = useState([]);
  const [docs, setDocs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setDownloadModal] = useState(false);
  const [loadingDetalle, setLoadingDetalle] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [rowCount, setRowCount] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const { archivosListado, loadingArchivos } = useSelector((state) => state.solicitudesAdm);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const obtenerArchivo = async (archivoId) => {
    setDocs([
      {
        uri: process.env.REACT_APP_BASEURL + 'admin/archivos/File/' + archivoId,
      },
    ]);
    setShowModal(true);
  };

  const descargarArchivo = async (archivoId, name) => {
    dispatch(ObtenerArchivo({ id: archivoId, name: name })).then(
      ({ payload }) => {
        if (payload.operationStatus === OperationStatus.SUCCESS) {
          toast.success(payload.message);
        } else {
          toast.error(payload.message);
        }
      }
    );
  };

  const onSubmit = async (data) => {
    setLoadingDetalle(true);
    let { payload } = await dispatch(DescargarArchivos(data.id));
    setLoadingDetalle(false);
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      setDownloadModal(false);
      reset();
    } else {
      toast.error(payload.message);
    }
  };

  useEffect(() => {
    const fn = async () => {
      const result = await dispatch(obtenerArchivosLista({ rowCount, page: currentPage }));
      setTableData(result.payload.result);
    }

    fn();
  }, [currentPage, rowCount]);

  const handleTextChange = (e) => {
    let val = e.target.value.toLowerCase();

    let res = archivosListado.filter(
      (x) =>
        x.archivoNombre.toLowerCase().indexOf(val) > -1 ||
        x.nombreCompleto.toLowerCase().indexOf(val) > -1
    );
    setTableData(res);
  };

  const closeModalEdit = () => {
    setShowModal(false);
  };

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Listado de archivos</h2>

        <div className="header-admin-bar-items ml-auto">
          <label hidden>Búsqueda</label>
          <input
            type="search"
            placeholder="Búsqueda"
            onInput={handleTextChange}
            className="form-control"
          />
        </div>
        <button
          className="search-button"
          onClick={() => setDownloadModal(true)}
        >
          <i className="material-icons"></i>
        </button>
      </div>

      <div className="table-container relative-class">
        {loadingArchivos && (
          <div className="loading-background">
            <LoadingSpiner
              show
              styles={{
                top: '45%',
                left: '50%',
                width: '50px',
                height: '50px',
                position: 'absolute',
                borderWidth: '10px',
                borderStyle: 'solid',
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
                borderImage: 'initial',
              }}
            />
          </div>
        )}
        {archivosListado?.result?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={tableData}
              clase="table-adm"
              actions={[
                {
                  icon: '',
                  clase: 'text-primary',
                  event: (item) => obtenerArchivo(item.archivoId),
                },
                {
                  icon: '',
                  clase: 'text-primary',
                  event: (item) =>
                    descargarArchivo(item.archivoId, item.archivoNombre),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
      <Pagination
        rowCount={rowCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowCountChange={setRowCount}
        totalPages={!loadingArchivos ? archivosListado.pageCount ?? 1 : 1}
      />
      <FileViewer
        estado={showModal}
        cambiarEstado={closeModalEdit}
        docs={docs}
      />
      <Modal
        estado={showDownloadModal}
        cambiarEstado={() => {
          setDownloadModal(false);
          reset();
        }}
        titulo={'Descargar archivos'}
        clase={`${width < 576 ? 'full-width' : 'small'}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Descargar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Id</label>
            <Required />
            <input
              className="form-control"
              type="text"
              {...register('id', {
                required: 'Campo requerido',
              })}
            />
            {errors.id && <p className="error">{errors.id.message}</p>}
          </div>
        </form>
      </Modal>
      <LoadingSpiner
        message={loadingMessage}
        show={loadingDetalle}
        fullPage={true}
        styles={{
          top: '45%',
          left: '50%',
          position: 'inherit',
          width: '50px',
          height: '50px',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
          borderImage: 'initial',
        }}
      />
    </main>
  );
}

export default ListaArchivos;
