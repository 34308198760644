import React from 'react'

const MessageInfo = ({message}) => {
  return (
    <div className='message-info'>
      {message}
    </div>
  )
}

export default MessageInfo
