import React from 'react'

/**
 * Pagination component
 * 
 * @param {number} currentPage 
 * @param {number} totalPages 
 * @param {number} rowCount 
 * @param {function} onPageChange 
 * @param {function} onRowCountChange 
 */
const Pagination = ({ currentPage, totalPages, rowCount, onPageChange, onRowCountChange }) => {
    const handlePrevious = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };

    const handleChangeRowCount = (e) => {
        onPageChange(1);
        onRowCountChange(e.target.value);
    }

    return (
        <div className="pagination page-title-container">
            <button className="page-button" onClick={handlePrevious} disabled={currentPage === 1}>
                Atrás
            </button>
            <span>
                Página {currentPage} de {totalPages}
            </span>
            <button className="page-button" onClick={handleNext} disabled={currentPage === totalPages}>
                Siguiente
            </button>
            <select
                value={`${rowCount}`}
                className="select"
                onChange={handleChangeRowCount}
            >
                <option hidden>Cantidad por página</option>
                <option value={15}>15/página</option>
                <option value={30}>30/página</option>
                <option value={50}>50/página</option>
                <option value={100}>100/página</option>
            </select>
        </div>
    )
}

export default Pagination